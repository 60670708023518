export const defaultSideBarLinks = [
  { title: 'Paul Cezanne', link: '/paul-cezanne-biography' },
  { title: 'Edouard Manet', link: '/edouard-manet-biography' },
  { title: 'Claude Monet', link: '/claude-monet-biography' },
  { title: 'Pierre-Auguste Renoir', link: '/pierre-auguste-renoir-biography' },
  { title: 'Edgar Degas', link: '/edgar-degas-biography' },
]

export const suggestionLink = {
  title: '2-minute quiz',
  link: '/quiz',
}
